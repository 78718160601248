import Head from "next/head";

interface MetaTagsProps {
  title?: string | null;
  description?: string | null;
  url?: string | null;
  image?: string | null;
  author?: string | null;
}

export default function MetaTags({ title, description, url, image, author }: MetaTagsProps) {
  const defaultTitle = "Candao";
  const defaultDescription = "Turn your Passions into Actions";
  const defaultUrl = "https://candao.io";
  const defaultImage = "https://cdn.candao.io/PressKit/Icon/Candao%20Icon.png";

  return (
    <Head>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:url" content={url || defaultUrl} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta
        property="og:description"
        content={description || defaultDescription}
        key="og:description"
      />
      <meta property="og:site_name" content="candao.io" />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:image:secure_url" content={image || defaultImage} />
      <meta property="og:image:alt" content="Candao Logo" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content={author ? "article" : "website"} />
      <meta property="og:logo" content="https://cdn.candao.io/PressKit/Icon/Candao%20Icon.png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title || defaultTitle} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta name="twitter:site" content="@Candao_io" />
      <meta name="twitter:image" content={image || defaultImage} />
      <meta http-equiv="cache-control" content="no-cache" />
      <meta http-equiv="expires" content="0" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta name="robots" content="noindex" />
      <link rel="icon" href="/favicon.ico" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />

      {author && (
        <>
          <meta name="author" content={author} />
          <meta name="twitter:label1" content="Written by" />
          <meta name="twitter:data1" content={author} />
        </>
      )}
    </Head>
  );
}
