import "../styles/main.scss";
import "swiper/css";

import { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import Head from "next/head";
import router from "next/router";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { ZIM, ZIMConversation } from "zego-zim-web";

import useUserData from "@/hooks/useUserData";
import { getUser } from "@/queries/users/user.query";
import { getUserByUsername } from "@/queries/users/username.query";
import { ErrorDtoNew } from "@/types/error.dto";
import MetaTags from "@/v2/components/meta-tags";
import { consoleIntegration } from "@sentry/nextjs";

import { generateToken } from "../components/sections/pages/messages/generate-token";
import NextI18nextConfig from "../next-i18next.config";
import Providers from "../Providers/Providers";
import { wrapper } from "../Redux/stores/store";
import { analytics } from "../utils/firebase-config";
import IntercomIntegration from "./Intercom-integration";

import type { AppProps } from "next/app";
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [zim, setZim] = useState<ZIM | null>(null);
  const getUser = useUserData();
  const appID =
    process.env.NEXT_PUBLIC_APP_CONFIG === "prod"
      ? parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_PROD || "0")
      : process.env.NEXT_PUBLIC_APP_CONFIG === "staging"
        ? parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_STAGE || "0")
        : parseInt(process.env.NEXT_PUBLIC_ZEGO_APP_ID_DEV || "0");

  useEffect(() => {
    if (typeof window !== "undefined") {
      (async () => {
        const { ZIM } = await import("zego-zim-web");
        ZIM.create({ appID });
        const zimInstance = ZIM.getInstance();
        zimInstance.setLogConfig({ logLevel: "disable" });
        setZim(zimInstance);
      })();
    }
  }, [appID]);

  const updateZimUserProfile = () => {
    if (!zim || !getUser) return;
    zim.updateUserAvatarUrl(
      getUser?.profile.avatarUrl ||
        "https://dev-cdn.candao.io/feed%2F27586dd8-13a8-4d91-9d7c-71a1c8e9f0e9.png"
    );
    zim.updateUserName(getUser?.username);
    zim.updateUserExtendedData(
      getUser?.profile.firstName.concat(" ").concat(getUser?.profile.lastName) || ""
    );
  };

  useEffect(() => {
    if (!getUser || !zim) {
      return;
    }
    zim.setLogConfig({ logLevel: "disable" });
    const userInfo = { userID: getUser.id, userName: getUser?.username || "" };
    const token = generateToken(userInfo.userID, 10800);
    zim.login(userInfo as any, token as any).catch(function (err) {
      console.error(err);
    });
    updateZimUserProfile();
  }, [zim, getUser]);

  useEffect(() => {
    analytics;
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <MetaTags
        title={pageProps?.title}
        description={pageProps?.description}
        url={pageProps?.url}
        image={pageProps?.image}
        author={pageProps?.author}
      />
      <IntercomIntegration />
      <Providers>
        <ProfileHomePage pageProps={pageProps} Component={Component} getLayout={getLayout} />
      </Providers>
    </>
  );
}

const ProfileHomePage = ({ pageProps, Component, getLayout }: any) => {
  if (pageProps.user) {
    const userData = GetByUsername(pageProps.user);
    if (userData) {
      pageProps = { ...pageProps, userData };
    }
  }

  return <>{getLayout(<Component {...pageProps} />)}</>;
};

const GetByUsername = (username: string) => {
  const [getUserData, setUserData] = useState<any>({
    info: {},
    userDataInit: false,
  });

  const userByUsername = useMutation(getUserByUsername, {
    retry: false,
    onSuccess: (data) => {
      const userData = data;
      if (userData) {
        setUserData({ info: userData, userDataInit: true, userFullData: false });
      }
    },
    onError: (e: ErrorDtoNew) => {
      // router.push("/404");
    },
  });

  const userByID = useMutation(getUser, {
    retry: false,
    onSuccess: (data) => {
      setUserData({
        info: { ...getUserData.info, ...data },
        userDataInit: true,
        userFullData: true,
      });
    },
    onError: (e: ErrorDtoNew) => {
      //   setTimeout(() => {
      // router.reload()
      //   }, 1000);
    },
  });

  useEffect(() => userByUsername.mutate(username), []);
  useEffect(() => {
    if (getUserData.info.id && !getUserData.userFullData) {
      userByID.mutate(getUserData.info.id);
    }
  }, [getUserData]);

  if (getUserData) {
    return getUserData;
  }
  return;
};

// @ts-ignore
//export default appWithTranslation(wrapper.withRedux(App), NextI18nextConfig)
export default wrapper.withRedux(App);
