import dynamic from "next/dynamic";
import React, { PropsWithChildren } from "react";

import { ModalContextProvider } from "@/v2/contexts/modal.context";

import ReactQueryProvider from "./ReactQueryProvider";
import SessionProvider from "./SessionProvider/SessionProvider";
import ToastProvider from "./ToastProvider";
import { usePathname } from "next/navigation";
import { useModal } from "@/v2/hooks/use-modal.hook";
import ErrorModal from "@/v2/modals/error";
import ErrorModalProvider from "./ErrorProvider";
import { APIProvider } from "@vis.gl/react-google-maps";

const WagmiProvider = dynamic(() => import("./WagmiProvider"), {
  ssr: false,
});

const Providers = ({ children }: PropsWithChildren) => {
  const pathname = usePathname();
  // IGNORE SSO PAGE
  if (pathname?.includes("/sso"))
    return (
      <ReactQueryProvider>
        <WagmiProvider>
          {/* <ConfigProvider> */}
          <ModalContextProvider>
            <ToastProvider />
            {children}
          </ModalContextProvider>
          {/* </ConfigProvider> */}
        </WagmiProvider>
      </ReactQueryProvider>
    );

  return (
    <ReactQueryProvider>
      <WagmiProvider>
        <ModalContextProvider>
          {/* <ConfigProvider> */}
          <SessionProvider>
             <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS as string}>
            <ToastProvider />
            <ErrorModalProvider />
            {children}</APIProvider>
          </SessionProvider>
          {/* </ConfigProvider> */}
        </ModalContextProvider>
      </WagmiProvider>
    </ReactQueryProvider>
  );
};

export default Providers;
